/* App.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f8ff;  /* 天蓝色背景 */
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  color: #4682b4;  /* 深蓝色 */
  margin-bottom: 20px;
  font-size: 2rem;
}

div {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: auto;
}

input[type="text"] {
  width: calc(100% - 40px);
  padding: 12px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  transition: border 0.3s;
}

input[type="text"]:focus {
  border: 1px solid #4682b4;  /* 蓝色聚焦边框 */
  outline: none;
}

button {
  padding: 12px 15px;
  background-color: #4682b4;  /* 蓝色按钮 */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  width: 100%;
}

button:hover {
  background-color: #5a9bd5;  /* 按钮悬停时颜色变化 */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #e6f0f9;  /* 每个任务的背景 */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

input[type="checkbox"] {
  margin-right: 15px;
}

button.delete {
  margin-left: 15px;
  background-color: #da6e63;  /* 删除按钮颜色 */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
}

button.delete:hover {
  background-color: #C0392B;
}

span {
  flex-grow: 1;
  font-size: 16px;
  transition: text-decoration 0.3s;
}

span[style*="line-through"] {
  color: #888;
}

input[type="date"] {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 6px;
  font-size: 14px;
  margin-left: 15px;
  transition: border 0.3s;
}

input[type="date"]:focus {
  border: 1px solid #4682b4;  /* 蓝色日期选择框 */
}

input[type="date"]:disabled {
  background-color: #f9f9f9;
}
